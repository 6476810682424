import React, {useEffect, useRef, useState} from 'react'
import {Button, Dropdown, Form, Modal} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {TeamMembersColumn} from "../../organizations/admin/teams/TeamMembersColumn";
import {TeamServicesColumn} from "../../organizations/admin/teams/TeamServicesColumn";
import FilterComponent from "../FilterComponent";
import {Link, useParams} from "react-router-dom";
import {faDownload, faEllipsisH, faFileUpload, faFolderPlus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TeamNewFileModal} from "./TeamNewFileModal";
import ModalDialog from "react-bootstrap/ModalDialog";
import Draggable from 'react-draggable';
import axios from "axios";
import {zynity_api} from "../../../config/external-routes";
import Swal from "sweetalert2";
import {useSelector} from "react-redux";
import {getCnAData} from "../../../store/user_organizations/teams/tools/connect_and_align";

export const TeamFilesModal = ({showCreateModal, handleCloseModal, DraggableModalDialog, setShowParentModal, setShowCurrentModal, addTeamFileToList}:any) => {
    const [showNewTeamFilesModal, setShowNewTeamFilesModal] = useState(false);
    const [showMoreSubItem, setShowMoreSubItem] = useState(false);
    const {organization_id, team_id} = useParams();

    const [data, setData] = useState([
        {
            id: 1,
            name: 'Invoice24324.xls',
            created_at: 'Feb 02 2021',
        },
        {
            id: 2,
            name: 'My document final.xls',
            created_at: 'May 21 2020',
        },
        {
            id: 3,
            name: 'users.pdf',
            created_at: 'Jul 12 2019',
        },
        {
            id:4,
            name: 'assesments.doc',
            created_at: 'Jul 13 2019',
        },
        {
            id: 5,
            name: 'projects_november2021.pdf',
            created_at: 'Oct 31 2018',
        }
    ]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [filterText, setFilterText] = React.useState(null);

    const loadTeamFiles = async () => {
        setLoading(true)
        let url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id);
        url += `/documents_by_team`;
        await axios.get(`${url}`)
            .then((response) => {
                setLoading(false)
                setData(response.data)
                console.log("response", response.data)
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `We have problems getting the files, please refresh the page and try again!`,
                })
                throw error;
            })
    }

    useEffect(() => {
        loadTeamFiles()
    }, [])


    const closeModal = () => {
        handleCloseModal()
        setShowParentModal(true)
    }

    const handleSubMenuToggle = () => {
        setShowMoreSubItem(!showMoreSubItem);
    };

    const formatDate = (isoString: string): string => {
        const date = new Date(isoString);
        return new Intl.DateTimeFormat('en-US', {
            dateStyle: 'long', // Full month name and day
            timeStyle: 'short', // Hours and minutes
        }).format(date);
    };

    const columns = [
        {
            name: 'File',
            selector: (row: { TeamName: any; }) => row.document.displayName,
            maxWidth: '250px'
        },
        {
            name: 'Added at',
            selector: (row: { TeamName: any; }) => formatDate(row.created_at),
            maxWidth: '250px'
        },
        {
            name: '',
            cell: (row: any) => {
                return  (
                    <>
                        <a href={`${zynity_api.global_download_files}?path=${row.document.path}&force_download=true&custom_name=${row.document.displayName}`}>
                            <button className="btn btn-teal mx-1">
                                <FontAwesomeIcon className='clickable mt-1' style={{width: '27px', fontSize: '18px'}} icon={faDownload}/>
                            </button>
                        </a>
                        <button className="btn btn-teal mx-1"
                                onClick={() => {addTeamFileToList(row.id, row.document.path, row.document.displayName); closeModal();}}
                        >
                            Add File
                        </button>
                    </>
                )
            },
            maxWidth: '200px'
        }
    ];

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            setFilterText('');
        };

        const handleFilter = (e: { target: { value: any; }; }) => {
            setFilterText(e.target.value)
        }

        return (
            <>
                <FilterComponent onFilter={handleFilter} onClear={handleClear} filterText={filterText} />
            </>
        );
    }, [filterText]);

    const handleCloseTeamFilesModal = () => {
        setShowNewTeamFilesModal(false)
        setShowCurrentModal(true)
        loadTeamFiles()
    };

    class DraggableModalNewTeamFilesDialog extends React.Component {
        render() {
            return <Draggable handle=".draggableHandler"><ModalDialog {...this.props} />
            </Draggable>
        }
    }

    const handleShowNewFileModal = () => {
        setShowNewTeamFilesModal(true)
        if(typeof setShowCurrentModal === 'function') {
            setShowCurrentModal(false)
        }
    }

    return (
        <>
            <Modal backdrop="static" show={showCreateModal} onHide={closeModal} dialogClassName="custom-modal-width"
                   dialogAs={DraggableModalDialog}
            >
                <Modal.Header closeButton className="bg-teal draggableHandler d-flex justify-content-between" style={{ cursor: 'grab' }}>
                    <div className="d-flex w-100 justify-content-between align-items-center">
                        <Modal.Title>Team Files</Modal.Title>
                        <button className="btn btn-outline-light ms-auto me-2" onClick={handleShowNewFileModal}>Upload New File</button>
                    </div>
                </Modal.Header>

                <Modal.Body className='row d-flex align-items-center'>
                    <div className="row">
                        <div className="col-12">
                            <DataTable
                                columns={columns}
                                data={data}
                                progressPending={loading}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <TeamNewFileModal
                showCreateModal={showNewTeamFilesModal}
                handleCloseModal={handleCloseTeamFilesModal}
                handleCloseParentModal={closeModal}
                DraggableModalDialog={DraggableModalNewTeamFilesDialog}
                setShowParentModal={setShowCurrentModal}
                addTeamFileToList={addTeamFileToList}
            />
        </>
    )
}
