import React, {useEffect, useRef, useState} from 'react'
import {Button, Dropdown, Form, Modal} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {TeamMembersColumn} from "../../organizations/admin/teams/TeamMembersColumn";
import {TeamServicesColumn} from "../../organizations/admin/teams/TeamServicesColumn";
import FilterComponent from "../FilterComponent";
import {Link, useParams} from "react-router-dom";
import {faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import {zynity_api} from "../../../config/external-routes";
import Swal from "sweetalert2";
import {useSelector} from "react-redux";

export const TeamNewFileModal = ({showCreateModal, handleCloseModal, handleCloseParentModal, DraggableModalDialog, setShowParentModal, addTeamFileToList}:any) => {
    const {organization_id, team_id} = useParams();
    const authenticated_user = useSelector(state => state.auth)
    const inputFileRef = useRef( null );
    const [displayName, setDisplayName] = useState('');
    const [fileName, setFileName] = useState('');
    const [uploading, setUploading] = useState(0);


    const closeModal = () => {
        handleCloseModal()
        setFileName('')
        setDisplayName('')
    }

    const addUploadHandle = (e:any) => {
        const formData = new FormData();
        let file = e.target.files[0];
        formData.append('file', file, file.name)
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${authenticated_user.access_token}`
            },
            onUploadProgress: function(progressEvent:any) {
                setUploading(Math.round( (progressEvent.loaded * 100) / progressEvent.total ));
            }
        }
        axios.post(zynity_api.global_upload_temp_files, formData, config).then(response => {
            setUploading(0);
            setFileName(response.data.file_name)
            setDisplayName(response.data.display_name.substring(0, response.data.display_name.lastIndexOf('.')))
        }).catch((e) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'We have problems uploading your file!',
            })
        })
    }

    let file_name_extension = '';
    if(fileName.trim() != '') {
        file_name_extension = '.' + fileName.split('.').pop();
    }

    const handleSubmit = () => {
        //organization_id, team_id
        let payload = {
            type: 'internal_doc',
            temp_file: fileName,
            display_name: displayName + file_name_extension,
        }

        let url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id);
        url += `/documents`;

        axios.post(url, payload).then(response => {
            setUploading(0);
            addTeamFileToList(response.data.id, fileName, displayName + file_name_extension)
            closeModal()
            handleCloseParentModal()
        }).catch((e) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'We have problems uploading your file!',
            })
        })
    }

    return (
        <Modal backdrop="static" show={showCreateModal} onHide={closeModal} dialogClassName="custom-modal-width"
               dialogAs={DraggableModalDialog}
        >
            <Modal.Header closeButton className="bg-teal draggableHandler d-flex justify-content-between" style={{ cursor: 'grab' }}>
                <Modal.Title>Upload New File</Modal.Title>
            </Modal.Header>

            <Modal.Body className='row d-flex align-items-center'>
                <div className="col-3">
                    <div className="mb-3">
                        <button className='btn btn-teal' onClick={() => inputFileRef.current.click()}>Select File</button>
                        <input type="file"
                               style={{display: 'none'}}
                               ref={inputFileRef}
                               onChange={addUploadHandle}
                        />
                    </div>
                </div>
                <div className="col-9" style={{marginLeft: '-30px'}}>
                    <div className="mb-3">
                        <label
                            htmlFor="displayName"
                            className="form-label"
                        >File Name
                        </label>
                        <div className="input-group mb-3">
                            <input type="text"
                                   className="form-control"
                                   id="displayName"
                                   name="displayName"
                                   value={displayName}
                                   onChange={(e) => setDisplayName(e.target.value)}
                                   placeholder="File Name"
                                   autoComplete="off"
                                   aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                <span className="input-group-text" id="basic-addon2">{file_name_extension}</span>

                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Cancel
                </Button>
                <Button className='btn-teal' onClick={handleSubmit} disabled={uploading === 1}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
