import React, {useEffect, useState} from 'react';
import axios from "axios";
import {zynity_api} from "../../../config/external-routes";
import FilterComponent from "../../ui/FilterComponent";
import {ActionDropdown} from "../ActionDropdown";
import {Header} from "../../header/Header";
import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom';


export const CoachesManage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(15);
    const [filterText, setFilterText] = React.useState(null);

    const fetchData = async page => {
        setLoading(true);
        setCurrentPage(page);
        let filter = filterText !== null && filterText !== '' ? `&search_text=${filterText}` : '';
        const response = await axios.get(`${zynity_api.admin_coaches}?page=${page}&limit=${perPage}${filter}`);

        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchData(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        setCurrentPage(page);
        let filter = filterText !== null && filterText !== '' ? `&search_text=${filterText}` : '';
        const response = await axios.get(`${zynity_api.admin_coaches}?page=${page}&limit=${newPerPage}${filter}`);

        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            setFilterText('');
        };

        const handleFilter = (e) => {
            setFilterText(e.target.value)
        }

        return (
            <FilterComponent onFilter={handleFilter} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText]);

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            maxWidth: '10px'
        },
        {
            name: 'Date',
            selector: row => {
                const date = new Date(row.Date);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}/${month}/${day}`;
            },
            maxWidth: '120px'
        },
        {
            name: 'Name',
            selector: row => `${row.FirstName} ${row.LastName}`,
        },
        {
            name: 'Org Name',
            selector: row => row.OrgName,
            maxWidth: '200px'
        },
        {
            name: 'City, State',
            selector: row => `${row.City} ${row.State}`,
            maxWidth: '160px'
        },
        {
            name: 'Phone',
            selector: row => row.Phone,
            maxWidth: '120px'
        },
        {
            name: 'Email',
            selector: row => row.Email,
            maxWidth: '200px'
        },
        {
            name: 'Status',
            selector: row => row.Status,
            maxWidth: '60px'
        },
        {
            name: 'Show Public',
            selector: row => row.ShowPublic,
            maxWidth: '60px'
        },
        {
            name: 'Actions',
            cell: row => <ActionDropdown
                linkView={`/admin/coaches/${row.id}`}
                linkEdit={`/admin/coaches-update/${row.id}`}
                linkDelete={`${zynity_api.admin_coaches}/${row.id}`}
                messageBeforeToDelete={`Do you want to delete the Coach: ${row.FirstName} (ID: ${row.id})?`}
                fetchData={fetchData}
                currentPage={currentPage} />,
            allowOverflow: true,
            button: true
        }
    ];

    useEffect(() => {
        fetchData(1)
    }, []);

    useEffect(() => {
        if(filterText !== null) {
            const timeOutId = setTimeout(() => fetchData(1), 500);
            return () => clearTimeout(timeOutId);
        }
    }, [filterText]);

    return (
        <>
            <Header/>
            <div className="container">
                <div className="row">
                    <div className="col-12 user-main-tittle">
                        <h4>
                            Coaches Manage
                            <Link to='/admin/z-admin' style={{ fontSize: '12px', textDecoration: 'none'}}>&nbsp;&nbsp;&nbsp;&nbsp;&laquo; Z Admin</Link>
                        </h4>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <DataTable
                            columns={columns}
                            data={data}
                            progressPending={loading}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            paginationPerPage={perPage}
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
